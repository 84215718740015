@media all and (min-width: 480px) {
    .Signup {
      padding: 60px 0;
    }
  
    .Signup form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
  
.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}